import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Banner from './components/Banner';
import SearchForm from './components/SearchForm';
import FeaturedListings from './components/FeaturedListings';
import AdBanner from './components/AdBanner';
import ListingsPage from './components/ListingsView/ListingsPage';
import CreateListing from './components/listings/CreateListing';
import AddListingView from './components/listings/AddListingView';
import Register from './components/Register';
import ListingDetails from './components/listings/ListingDetails';
import Contact from './components/Contact';
import Profile from './components/Profile'; // Import dla komponentu Profile
import FavoritesPage from './components/FavoritesPage'; // Strona ulubionych
import { FavoritesProvider } from './FavoritesContext'; // Import kontekstu Favorites
import ScrollToTop from './ScrollToTop'; // Import ScrollToTop

import 'leaflet/dist/leaflet.css'; // Styl dla mapy Leaflet
import DescriptionInput from './components/listings/DescriptionInput';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (
    <FavoritesProvider>
      <Router>
        <ScrollToTop /> {/* Dodano ScrollToTop */}
        <div className="flex flex-col min-h-screen">
          <Navigation 
            isLoggedIn={isLoggedIn} 
            handleLogout={handleLogout} 
            setIsLoggedIn={setIsLoggedIn} 
          />
          
          <main className="flex-grow">
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Banner />
                    <SearchForm />
                    <FeaturedListings />
                    <AdBanner />
                  </>
                }
              />
              <Route path="/listings" element={<ListingsPage />} />
              <Route path="/listing/:id" element={<ListingDetails />} />
              <Route path="/createlisting" element={<CreateListing />} />
              <Route path="/register" element={<Register setIsLoggedIn={setIsLoggedIn} />} />
              <Route
                path="/addlistingview"
                element={
                  <>
                    <AddListingView />
                    <DescriptionInput />
                  </>
                }
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/favorites" element={<FavoritesPage />} /> {/* Trasa ulubionych */}
            </Routes>
          </main>

          <Footer />
        </div>
      </Router>
    </FavoritesProvider>
  );
}

export default App;
