// CreateListing.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PhotoUpload from './PhotoUpload';

const CreateListing = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: '',
    headline: '',
    vin: '',
    registrationNumber: '',
    condition: '',
    accidentStatus: '',
    damageStatus: '',
    imported: '',
    registeredInPL: '',
    firstOwner: '',
    tuning: '',
    disabledAdapted: '',
    bodyType: '',
    color: '',
    productionYear: '',
    mileage: '',
    countryOfOrigin: '',
    brand: '',
    model: '',
    generation: '',
    version: '',
    fuelType: '',
    power: '',
    engineSize: '',
    transmission: '',
    drive: '',
    doors: '',
    weight: '',
    voivodeship: '',
    city: '',
    photos: []
  });

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleVinSearch = () => {
    console.log('Szukanie VIN:', formData.vin);
  };

  const handlePhotosChange = (photos) => {
    setFormData(prev => ({
      ...prev,
      photos
    }));
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="max-w-4xl mx-auto">
        <form className="bg-white p-6 rounded-lg shadow mb-8">
          <div className="mb-6">
            <h3 className="bg-green-700 text-white p-2 rounded">Wyszukaj samochód po numerze VIN lub numerze rejestracyjnym</h3>
            <div className="flex gap-2 mt-2">
              <input
                type="text"
                value={formData.vin}
                onChange={(e) => handleChange('vin', e.target.value)}
                placeholder="Wprowadź numer VIN lub numer rejestracyjny"
                className="flex-1 p-2 border rounded focus:ring-green-700 focus:border-green-700"
              />
              <button
                type="button"
                onClick={handleVinSearch}
                className="bg-green-700 text-white px-4 py-2 rounded hover:bg-green-600 focus:ring-green-700"
              >
               Pobierz Dane
              </button>
            </div>
          </div>

          <div className="mb-4">
            <input
              type="text"
              disabled
              value={formData.title}
              placeholder="Automatyczne uzupełnianie z tabelki: marka, model, generacja (bez roku), wersja"
              className="w-full p-2 border rounded bg-gray-50 focus:ring-green-700 focus:border-green-700"
            />
          </div>

          <div className="mb-6">
            <div className="flex">
              <label className="w-32 font-bold">Nagłówek:</label>
              <input
                type="text"
                value={formData.headline}
                onChange={(e) => handleChange('headline', e.target.value)}
                maxLength={60}
                placeholder="(Max znaków 60)"
                className="flex-1 p-2 border rounded focus:ring-green-700 focus:border-green-700"
              />
            </div>
          </div>

          <div className="mb-6">
            <h3 className="bg-green-700 text-white p-2 mb-3 rounded">Stan pojazdu:</h3>
            <div className="grid grid-cols-6 gap-4">
              <label className="text-center">
                <input
                  type="radio"
                  name="condition"
                  value="Nowy"
                  checked={formData.condition === 'Nowy'}
                  onChange={(e) => handleChange('condition', e.target.value)}
                  className="text-green-700 focus:ring-green-700"
                />
                <span className="ml-1">Nowy</span>
              </label>
              <label className="text-center">
                <input
                  type="radio"
                  name="condition"
                  value="Używany"
                  checked={formData.condition === 'Używany'}
                  onChange={(e) => handleChange('condition', e.target.value)}
                  className="text-green-700 focus:ring-green-700"
                />
                <span className="ml-1">Używany</span>
              </label>
              <label className="text-center">
                <input
                  type="radio"
                  name="accidentStatus"
                  value="Bezwypadkowy"
                  checked={formData.accidentStatus === 'Bezwypadkowy'}
                  onChange={(e) => handleChange('accidentStatus', e.target.value)}
                  className="text-green-700 focus:ring-green-700"
                />
                <span className="ml-1">Bezwypadkowy</span>
              </label>
              <label className="text-center">
                <input
                  type="radio"
                  name="accidentStatus"
                  value="Powypadkowy"
                  checked={formData.accidentStatus === 'Powypadkowy'}
                  onChange={(e) => handleChange('accidentStatus', e.target.value)}
                  className="text-green-700 focus:ring-green-700"
                />
                <span className="ml-1">Powypadkowy</span>
              </label>
              <label className="text-center">
                <input
                  type="radio"
                  name="damageStatus"
                  value="Nieuszkodzony"
                  checked={formData.damageStatus === 'Nieuszkodzony'}
                  onChange={(e) => handleChange('damageStatus', e.target.value)}
                  className="text-green-700 focus:ring-green-700"
                />
                <span className="ml-1">Nieuszkodzony</span>
              </label>
              <label className="text-center">
                <input
                  type="radio"
                  name="damageStatus"
                  value="Uszkodzony"
                  checked={formData.damageStatus === 'Uszkodzony'}
                  onChange={(e) => handleChange('damageStatus', e.target.value)}
                  className="text-green-700 focus:ring-green-700"
                />
                <span className="ml-1">Uszkodzony</span>
              </label>
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              {[
                { label: 'Importowany', field: 'imported' },
                { label: 'Zarejestrowany w PL', field: 'registeredInPL' },
                { label: 'Pierwszy właściciel', field: 'firstOwner' },
                { label: 'Tuning', field: 'tuning' }
              ].map(option => (
                <div key={option.field} className="border p-2 rounded">
                  <h4 className="font-bold mb-2">{option.label}</h4>
                  <div className="flex gap-4">
                    <label>
                      <input
                        type="radio"
                        name={option.field}
                        value="Tak"
                        checked={formData[option.field] === 'Tak'}
                        onChange={(e) => handleChange(option.field, e.target.value)}
                        className="text-green-700 focus:ring-green-700"
                      />
                      <span className="ml-1">Tak</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name={option.field}
                        value="Nie"
                        checked={formData[option.field] === 'Nie'}
                        onChange={(e) => handleChange(option.field, e.target.value)}
                        className="text-green-700 focus:ring-green-700"
                      />
                      <span className="ml-1">Nie</span>
                    </label>
                  </div>
                </div>
              ))}

              <div className="col-span-2 border p-2 rounded">
                <h4 className="font-bold mb-2">Auto przystosowane pod osobę niepełnosprawną</h4>
                <div className="flex gap-4">
                  <label>
                    <input
                      type="radio"
                      name="disabledAdapted"
                      value="Tak"
                      checked={formData.disabledAdapted === 'Tak'}
                      onChange={(e) => handleChange('disabledAdapted', e.target.value)}
                      className="text-green-700 focus:ring-green-700"
                    />
                    <span className="ml-1">Tak</span>
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="disabledAdapted"
                      value="Nie"
                      checked={formData.disabledAdapted === 'Nie'}
                      onChange={(e) => handleChange('disabledAdapted', e.target.value)}
                      className="text-green-700 focus:ring-green-700"
                    />
                    <span className="ml-1">Nie</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="bg-green-700 text-white p-2 mb-3 rounded">Nadwozie</h3>
            <div className="grid grid-cols-3 gap-4">
              {[
                'Hatchback', 'Sedan', 'Kombi', 'SUV', 'Coupe',
                'Cabrio', 'Terenowe', 'Minivan', 'Dostawcze'
              ].map(type => (
                <label key={type} className="flex items-center">
                  <input
                    type="radio"
                    name="bodyType"
                    value={type}
                    checked={formData.bodyType === type}
                    onChange={(e) => handleChange('bodyType', e.target.value)}
                    className="text-green-700 focus:ring-green-700"
                  />
                  <span className="ml-2">{type}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="mb-6">
            <label className="block mb-2 font-bold">Kolor</label>
            <div>
              <span className="mr-2">Wybierz z:</span>
              <select
                value={formData.color}
                onChange={(e) => handleChange('color', e.target.value)}
                className="w-64 p-2 border rounded focus:ring-green-700 focus:border-green-700"
              >
                <option value="">Wybierz kolor</option>
                {[
                  'Czarny', 'Biały', 'Srebrny', 'Czerwony',
                  'Niebieski', 'Zielony', 'Żółty', 'Brązowy',
                  'Złoty', 'Szary', 'Inny'
                ].map(color => (
                  <option key={color} value={color}>{color}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="bg-green-700 text-white p-2 mb-3 rounded">Dane techniczne pojazdu</h3>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label className="block mb-1 font-bold">Rok produkcji</label>
                <input
                  type="number"
                  value={formData.productionYear}
                  onChange={(e) => handleChange('productionYear', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                />
              </div>
              <div>
                <label className="block mb-1 font-bold">Przebieg</label>
                <input
                  type="number"
                  value={formData.mileage}
                  onChange={(e) => handleChange('mileage', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                />
              </div>
              <div>
                <label className="block mb-1 font-bold">Kraj pochodzenia</label>
                <input
                  type="text"
                  value={formData.countryOfOrigin}
                  onChange={(e) => handleChange('countryOfOrigin', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                />
              </div>

              <div>
                <label className="block mb-1 font-bold">Marka*</label>
                <input
                  type="text"
                  required
                  value={formData.brand}
                  onChange={(e) => handleChange('brand', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                />
              </div>
              <div>
                <label className="block mb-1 font-bold">Model*</label>
                <input
                  type="text"
                  required
                  value={formData.model}
                  onChange={(e) => handleChange('model', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                />
              </div>
              <div>
                <label className="block mb-1 font-bold">Generacja*</label>
                <input
                  type="text"
                  required
                  value={formData.generation}
                  onChange={(e) => handleChange('generation', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                />
              </div>
              <div>
                <label className="block mb-1 font-bold">Wersja*</label>
                <input
                  type="text"
                  required
                  value={formData.version}
                  onChange={(e) => handleChange('version', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                />
              </div>

              <div>
                <label className="block mb-1 font-bold">Rodzaj paliwa*</label>
                <select
                  required
                  value={formData.fuelType}
                  onChange={(e) => handleChange('fuelType', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                >
                  <option value="">Wybierz rodzaj paliwa</option>
                  <option value="Benzyna">Benzyna</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Benzyna+LPG">Benzyna+LPG</option>
                  <option value="Elektryczny">Elektryczny</option>
                  <option value="Hybryda">Hybryda</option>
                </select>
              </div>

              <div>
                <label className="block mb-1 font-bold">Moc* (KM)</label>
                <input
                  type="number"
                  required
                  value={formData.power}
                  onChange={(e) => handleChange('power', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                />
              </div>

              <div>
                <label className="block mb-1 font-bold">Pojemność* (cm³)</label>
                <input
                  type="number"
                  required
                  value={formData.engineSize}
                  onChange={(e) => handleChange('engineSize', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                />
              </div>

              <div>
                <label className="block mb-1 font-bold">Skrzynia biegów*</label>
                <select
                  required
                  value={formData.transmission}
                  onChange={(e) => handleChange('transmission', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                >
                  <option value="">Wybierz typ</option>
                  <option value="Manualna">Manualna</option>
                  <option value="Automatyczna">Automatyczna</option>
                </select>
              </div>

              <div>
                <label className="block mb-1 font-bold">Napęd*</label>
                <select
                  required
                  value={formData.drive}
                  onChange={(e) => handleChange('drive', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                >
                  <option value="">Wybierz napęd</option>
                  <option value="Przedni">Przedni</option>
                  <option value="Tylny">Tylny</option>
                  <option value="4x4">4x4</option>
                </select>
              </div>

              <div>
                <label className="block mb-1 font-bold">Liczba drzwi*</label>
                <input
                  type="number"
                  required
                  min="2"
                  max="5"
                  value={formData.doors}
                  onChange={(e) => handleChange('doors', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                />
              </div>

              <div>
                <label className="block mb-1 font-bold">Waga samochodu (kg)</label>
                <input
                  type="number"
                  value={formData.weight}
                  onChange={(e) => handleChange('weight', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                />
              </div>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="bg-green-700 text-white p-2 mb-3 rounded">Lokalizacja:</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block mb-1 font-bold">Województwo</label>
                <select
                  value={formData.voivodeship}
                  onChange={(e) => handleChange('voivodeship', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                >
                  <option value="">Wybierz województwo</option>
                  {[
                    'Dolnośląskie', 'Kujawsko-pomorskie', 'Lubelskie',
                    'Lubuskie', 'Łódzkie', 'Małopolskie', 'Mazowieckie',
                    'Opolskie', 'Podkarpackie', 'Podlaskie', 'Pomorskie',
                    'Śląskie', 'Świętokrzyskie', 'Warmińsko-mazurskie',
                    'Wielkopolskie', 'Zachodniopomorskie'
                  ].map(woj => (
                    <option key={woj} value={woj}>{woj}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block mb-1 font-bold">Miejscowość</label>
                <input
                  type="text"
                  value={formData.city}
                  onChange={(e) => handleChange('city', e.target.value)}
                  className="w-full p-2 border rounded focus:ring-green-700 focus:border-green-700"
                />
              </div>
            </div>
          </div>
        </form>

        <div className="bg-white p-6 rounded-lg shadow mb-8">
          <PhotoUpload
            photos={formData.photos}
            onPhotosChange={handlePhotosChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateListing;