import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CarListings = () => {
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);

  useEffect(() => {
    const exampleListings = [
      { id: 1, title: 'BMW X5 2021', description: 'Luksusowy SUV z pełnym wyposażeniem, skórzana tapicerka, nawigacja. Wyjątkowy komfort i doskonałe osiągi sprawiają, że jest to idealny wybór dla najbardziej wymagających kierowców.', price: '120,000 PLN', imageUrl: '/images/automobile-1834278_640.jpg' },
      { id: 2, title: 'Audi A4 2020', description: 'Sedan klasy premium, napęd quattro, panoramiczny dach. Auto zaprojektowane z myślą o komforcie i precyzji jazdy, doskonale sprawdza się zarówno w mieście, jak i na trasie.', price: '95,000 PLN', imageUrl: '/images/dodge-challenger-8214392_640.jpg' },
      { id: 3, title: 'Mercedes-Benz C 200 2019', description: 'Silnik turbo, automat, perfekcyjny stan techniczny. Idealny wybór dla osób ceniących luksus i niezawodność. Samochód w stanie niemal fabrycznym.', price: '85,000 PLN', imageUrl: '/images/car-1880381_640.jpg' },
      { id: 4, title: 'Volkswagen Golf 2018', description: 'Ekonomiczny hatchback, niski przebieg, zadbany. Kompaktowy samochód świetnie nadający się zarówno na krótkie trasy miejskie, jak i dłuższe podróże.', price: '50,000 PLN', imageUrl: '/images/toyota-gr-yaris-6751752_640.jpg' },
      { id: 5, title: 'Ford Mustang 2017', description: 'Legendarny muscle car, potężny silnik V8. Auto o niesamowitej mocy i stylu, które zapewnia wyjątkowe emocje na drodze.', price: '150,000 PLN', imageUrl: '/images/car-1880381_640.jpg' },
      { id: 6, title: 'Toyota Corolla 2021', description: 'Nowoczesny i ekonomiczny, idealny do miasta. Samochód o wyjątkowej niezawodności i niskim zużyciu paliwa, świetny na codzienne dojazdy.', price: '65,000 PLN', imageUrl: '/images/automobile-1834278_640.jpg' },
      { id: 7, title: 'Kia Sportage 2019', description: 'SUV z napędem 4x4, zadbany, pierwszy właściciel. Przestronny i wszechstronny, doskonały wybór dla rodzin i miłośników przygód.', price: '70,000 PLN', imageUrl: '/images/toyota-gr-yaris-6751752_640.jpg' },
      { id: 8, title: 'Honda Civic 2020', description: 'Sportowy kompakt z niskim przebiegiem, dynamiczny. Nowoczesny design i świetne osiągi zapewniają przyjemność z każdej jazdy.', price: '60,000 PLN', imageUrl: '/images/dodge-challenger-8214392_640.jpg' },
    ];
    setListings(exampleListings);
  }, []);

  const renderListingCard = (listing) => (
    <div
      key={listing.id}
      className="bg-gray-50 shadow-md overflow-hidden flex flex-col"
      style={{ width: '280px' }}
    >
      {/* Obraz samochodu */}
      <div className="h-44">
        <img
          src={listing.imageUrl}
          alt={listing.title}
          className="w-full h-full object-cover"
        />
      </div>

      {/* Sekcja nazwy samochodu */}
      <div className="bg-gray-200 text-center py-2">
        <h3 className="text-base font-semibold text-gray-800">{listing.title}</h3>
        <div
          className="h-0.5 bg-green-500 mx-auto mt-1"
          style={{ width: '70%' }} // Zielona linia na 70% szerokości
        />
      </div>

      {/* Sekcja opisu */}
      <div className="bg-white py-4 flex flex-col justify-between">
        <p className="text-sm text-gray-600 text-center px-2 h-24 overflow-hidden">
          {listing.description}
        </p>
        <div
          className="h-0.5 bg-green-500 mx-auto mt-4"
          style={{ width: '70%' }} // Zielona linia na 70% szerokości
        />
      </div>

      {/* Sekcja ceny */}
      <div className="bg-green-900 p-3 flex items-center justify-center h-14">
        <span className="text-yellow-500 text-xl mr-2">💰</span>
        <span className="font-bold text-white text-lg">{listing.price}</span>
      </div>

      {/* Sekcja przycisku */}
      <div className="p-4 h-20 flex items-center">
        <button
          onClick={() => navigate(`/listing/${listing.id}`)}
          className="w-full bg-green-600 text-white py-2 rounded hover:bg-green-700 transition-colors"
        >
          Pokaż ogłoszenie
        </button>
      </div>
    </div>
  );

  return (
    <div className="py-12 bg-gray-100">
      <div className="container mx-auto px-6" style={{ maxWidth: '1200px' }}>
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-8">
          OGŁOSZENIA
          <div className="w-24 h-0.5 bg-green-600 mx-auto mt-2" /> {/* Cieńsza linia */}
        </h2>

        <div className="bg-white p-8 rounded-lg shadow-xl mx-auto">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
            {listings.map((listing) => (
              <div key={listing.id} className="flex justify-center">
                {renderListingCard(listing)}
              </div>
            ))}
          </div>

          <div className="text-center mt-10">
            <button
              onClick={() => navigate('/listings')}
              className="bg-green-600 text-white py-3 px-8 rounded text-lg hover:bg-green-700 transition-colors"
            >
              Przejdź do listy ogłoszeń
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarListings;
