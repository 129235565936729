import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MapPin, Phone, Mail, Facebook, MessageCircle, Flag, Edit2, Trash2, Heart } from 'lucide-react';
import ImageGallery from './details/ImageGallery';

const ListingDetails = () => {
 const { id } = useParams();
 const navigate = useNavigate();
 const [showPhoneNumber, setShowPhoneNumber] = useState(false);
 const [newComment, setNewComment] = useState('');
 const [comments, setComments] = useState([
   {
     id: 1,
     author: 'Jan Kowalski',
     text: 'Świetny samochód, miałem okazję oglądać na żywo. Polecam!',
     date: '2024-03-01',
     isEditing: false
   },
   {
     id: 2, 
     author: 'Anna Nowak',
     text: 'Czy możliwe jest negocjowanie ceny?',
     date: '2024-03-02',
     isEditing: false
   }
 ]);

 const listing = {
   id,
   title: 'Volkswagen Golf 1.4 TSI',
   price: '45 000 zł',
   images: [
     '/images/automobile-1834278_640.jpg',
     '/images/dodge-challenger-8214392_640.jpg',
     '/images/car-1880381_640.jpg'
   ],
   details: {
     Marka: 'Volkswagen',
     Model: 'Golf',
     Generacja: '7',
     'Wersja modelu': '1.4 TSI',
     Rok: '2021',
     Przebieg: '45 000 km',
     Paliwo: 'Benzyna',
     'Pojemność silnika': '1395 cm³',
     Moc: '122 KM',
     'Skrzynia biegów': 'Automatyczna',
     Napęd: 'Na przednie koła',
     'Typ nadwozia': 'Kompakt',
     Kolor: 'Srebrny metalik',
     'Liczba drzwi': '5',
     'Liczba miejsc': '5',
     'Stan techniczny': 'Bezwypadkowy',
     'Kraj pochodzenia': 'Niemcy',
     VIN: 'WVWZZZAUZ3W58**',
     'Pierwsza rejestracja': '01.2021'
   },
   location: {
     region: 'Mazowieckie',
     city: 'Warszawa'
   },
   contact: {
     phone: '+48 123 456 789',
     email: 'sprzedawca@autosell.pl'
   },
   description: `Jest to zadbany egzemplarz volkswagena golfa 1.4 TSI. Samochód jest w idealnym stanie technicznym i wizualnym. Auto było serwisowane wyłącznie w autoryzowanym serwisie Volkswagena, co jest potwierdzone książką serwisową. Wszystkie przeglądy były wykonywane na czas, zgodnie z zaleceniami producenta.

   Silnik 1.4 TSI o mocy 122 KM pracuje idealnie, jest dynamiczny i ekonomiczny. Średnie spalanie w cyklu mieszanym wynosi około 6,5l/100km. Automatyczna skrzynia biegów działa płynnie i bezproblemowo.
   Wyposażenie obejmuje m.in.:
   - Klimatyzację dwustrefową
   - System nawigacji satelitarnej
   - Czujniki parkowania przód i tył
   - Podgrzewane fotele
   - Światła LED
   - System Start-Stop
   - Tempomat adaptacyjny
   - Asystent pasa ruchu
   
   Auto jest bezwypadkowe, wszystkie elementy posiadają oryginalną powłokę lakierniczą. Wnętrze jest zadbane, bez śladów zużycia. Opony są w bardzo dobrym stanie.

   Samochód jest gotowy do jazdy, nie wymaga żadnych napraw ani dodatkowych inwestycji. Idealny zarówno do jazdy miejskiej jak i w trasę.

   Możliwość sprawdzenia auta w dowolnym serwisie.
   Możliwa zamiana z dopłatą w obie strony.
   Możliwość zakupu na kredyt lub leasing.`,
   viewCount: 123
 };

 const similarListings = [
   {
     id: 1,
     title: 'VW Golf 7 1.4 TSI',
     price: '50 000 zł',
     year: '2015',
     mileage: '150 000 km',
     image: '/images/automobile-1834278_640.jpg',
   },
   {
     id: 2,
     title: 'BMW 3 E36 1.8 is',
     price: '30 000 zł',
     year: '1993',
     mileage: '223 565 km',
     image: '/images/car-1880381_640.jpg',
   },
   {
     id: 3,
     title: 'Audi A3 2.0 TDI',
     price: '55 000 zł',
     year: '2018',
     mileage: '98 000 km',
     image: '/images/dodge-challenger-8214392_640.jpg',  
   }
 ];

 const handleShare = (platform) => {
   const url = encodeURIComponent(window.location.href);
   switch (platform) {
     case 'facebook':
       window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`);
       break;
     case 'messenger':
       window.open(`https://www.facebook.com/dialog/send?link=${url}`);
       break;
     case 'email':
       window.open(`mailto:?subject=${listing.title}&body=${url}`);
       break;
     case 'copy':
       navigator.clipboard.writeText(window.location.href);
       alert('Link został skopiowany do schowka!');
       break;
   }
 };

 const handleAddComment = () => {
   if (newComment.trim()) {
     const comment = {
       id: comments.length + 1,
       author: 'Użytkownik',
       text: newComment,
       date: new Date().toISOString().split('T')[0],
       isEditing: false
     };
     setComments([...comments, comment]);
     setNewComment('');
   }
 };

 const handleEditComment = (commentId) => {
   setComments(comments.map(comment => 
     comment.id === commentId 
       ? { ...comment, isEditing: !comment.isEditing }
       : comment
   ));
 };

 const handleSaveComment = (commentId, text) => {
   setComments(comments.map(comment =>
     comment.id === commentId
       ? { ...comment, text, isEditing: false }
       : comment
   ));
 };

 return (
   <div className="max-w-7xl mx-auto px-4 py-8">
     <button
       onClick={() => navigate(-1)}
       className="text-green-600 hover:text-green-700 mb-6 flex items-center gap-2"
     >
       ← Powrót do ogłoszeń
     </button>

     <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
       {/* Lewa i środkowa kolumna */}
       <div className="lg:col-span-2 space-y-6">
         <ImageGallery images={listing.images} />
         
         <div className="bg-white rounded-lg shadow-md p-6">
           <h2 className="text-xl font-bold mb-4 text-gray-800">Opis pojazdu</h2>
           <p className="text-gray-600 whitespace-pre-line">{listing.description}</p>
         </div>

         {/* Sekcja komentarzy */}
         <div className="bg-white rounded-lg shadow-md p-6">
           <h2 className="text-xl font-bold mb-4 text-gray-800">Komentarze</h2>
           <p className="text-sm text-red-500 mb-4">* Komentarze zawierające wulgarne treści będą usuwane</p>
           
           <div className="space-y-4 mb-6">
             {comments.map((comment) => (
               <div key={comment.id} className="border-b pb-4">
                 <div className="flex justify-between items-center mb-2">
                   <span className="font-semibold text-gray-800">{comment.author}</span>
                   <div className="flex items-center gap-2">
                     <span className="text-sm text-gray-500">{comment.date}</span>
                     <button onClick={() => handleEditComment(comment.id)} className="text-blue-500 hover:text-blue-600">
                       <Edit2 className="w-4 h-4" />
                     </button>
                     <button className="text-red-500 hover:text-red-600">
                       <Flag className="w-4 h-4" />
                     </button>
                   </div>
                 </div>
                 {comment.isEditing ? (
                   <div>
                     <textarea
                       className="w-full p-2 border rounded-lg mb-2"
                       defaultValue={comment.text}
                       rows={3}
                     />
                     <button
                       className="bg-blue-500 text-white px-4 py-1 rounded-lg text-sm"
                       onClick={() => handleSaveComment(comment.id, comment.text)}
                     >
                       Zapisz
                     </button>
                   </div>
                 ) : (
                   <p className="text-gray-600">{comment.text}</p>
                 )}
               </div>
             ))}
           </div>

           {/* Formularz dodawania komentarza */}
           <div>
             <textarea
               className="w-full p-3 border rounded-lg mb-2"
               placeholder="Dodaj komentarz..."
               value={newComment}
               onChange={(e) => setNewComment(e.target.value)}
               rows={3}
             />
             <button
               className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
               onClick={handleAddComment}
             >
               Dodaj komentarz
             </button>
           </div>
         </div>

         {/* Podobne ogłoszenia */}
         <div className="bg-white rounded-lg shadow-md p-6">
           <h2 className="text-xl font-bold mb-4 text-gray-800">Podobne ogłoszenia</h2>
           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
             {similarListings.map((item) => (
               <div key={item.id} className="border rounded-lg overflow-hidden cursor-pointer hover:shadow-lg">
                 <img 
                   src={item.image} 
                   alt={item.title}
                   className="w-full h-40 object-cover"
                 />
                 <div className="p-4">
                   <h3 className="font-semibold text-lg mb-2">{item.title}</h3>
                   <p className="text-green-600 font-bold mb-2">{item.price}</p>
                   <div className="text-sm text-gray-500">
                     <p>{item.year} • {item.mileage}</p>
                   </div>
                 </div>
               </div>
             ))}
           </div>
         </div>
       </div>

       {/* Prawa kolumna */}
       <div className="space-y-6">
         {/* Dane techniczne */}
         <div className="bg-white rounded-lg shadow-md p-6">
           <h2 className="text-xl font-bold mb-4 text-gray-800">Dane techniczne</h2>
           <table className="w-full text-sm text-left text-gray-600">
             <tbody>
               {Object.entries(listing.details).map(([key, value]) => (
                 <tr key={key} className="border-b">
                   <td className="py-2 font-medium text-gray-800">{key}</td>
                   <td className="py-2">{value}</td>
                 </tr>
               ))}
             </tbody>
           </table>
         </div>

         {/* Przyciski udostępniania */}
         <div className="bg-white rounded-lg shadow-md p-4">
           <div className="flex justify-center gap-4">
             <button
               onClick={() => handleShare('facebook')}
               className="p-2 hover:bg-gray-100 rounded-full text-blue-600"
             >
               <Facebook className="w-6 h-6" />
             </button>
             <button
               onClick={() => handleShare('messenger')}
               className="p-2 hover:bg-gray-100 rounded-full text-blue-500"
             >
               <MessageCircle className="w-6 h-6" />
             </button>
             <button
               onClick={() => handleShare('email')}
               className="p-2 hover:bg-gray-100 rounded-full text-red-500"
             >
               <Mail className="w-6 h-6" />
             </button>
           </div>
         </div>

         {/* Kontakt */}
         <div className="bg-white rounded-lg shadow-md p-6">
           <h2 className="text-xl font-bold mb-4 text-gray-800">
             Kontakt do sprzedającego
           </h2>
           <div className="flex items-center gap-2 mb-4">
             <MapPin className="w-5 h-5 text-gray-500" />
             <span className="text-gray-600">
               {listing.location.city}, {listing.location.region}
             </span>
           </div>
           <div className="flex flex-col gap-2">
             <button
               onClick={() => setShowPhoneNumber(!showPhoneNumber)}
               className="flex items-center gap-2 bg-green-600 text-white py-2 px-4 rounded-lg font-medium hover:bg-green-700 transition-colors"
             >
               <Phone size={20} />
               {showPhoneNumber ? listing.contact.phone : 'Pokaż numer'}
             </button>
             <button
               onClick={() => window.open(`mailto:${listing.contact.email}`)}
               className="flex items-center gap-2 bg-gray-100 py-2 px-4 rounded-lg font-medium hover:bg-gray-200 transition-colors"
             >
               <Mail size={20} />
               Napisz wiadomość
             </button>
           </div>
         </div>
       </div>
     </div>
   </div>
 );
};

export default ListingDetails;