import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginModal from './LoginModal';
import GuestNavigation from './GuestNavigation';
import UserNavigation from './UserNavigation';
import { FaBars, FaTimes } from 'react-icons/fa';

function Navigation() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const user = {
    name: 'Wojciech',
    avatar: '/path/to/avatar.png',
  };

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <header className="bg-green-800 text-white sticky top-0 z-50 font-sans shadow-md">
        <div className="w-full flex items-center justify-between px-8 py-3">
          {/* Logo */}
          <div className="flex items-center gap-3">
            <img src="/path/to/logo.png" alt="Logo" className="w-8 h-8 lg:w-10 lg:h-10" />
            <h2 className="text-xl lg:text-2xl font-bold tracking-wider uppercase">
              <Link to="/">AutoSell.PL</Link>
            </h2>
          </div>

          {/* Hamburger Menu */}
          <div className="md:hidden">
            <button
              onClick={toggleMobileMenu}
              className="text-white hover:text-[#123524] focus:outline-none"
            >
              {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>

          {/* Główne Menu */}
          <nav
            className={`${
              isMobileMenuOpen ? 'block' : 'hidden'
            } md:flex md:flex-grow justify-center items-center ml-4`}
          >
            <ul className="flex flex-col md:flex-row items-center gap-6 uppercase text-sm lg:text-base font-semibold tracking-wider">
              <li>
                <Link to="/" className="hover:text-[#123524] transition-colors">
                  Strona główna
                </Link>
              </li>
              <li>
                <Link to="/listings" className="hover:text-[#123524] transition-colors">
                  Lista ogłoszeń
                </Link>
              </li>
              {isLoggedIn && (
                <li>
                  <Link to="/favorites" className="hover:text-[#123524] transition-colors">
                    Ulubione
                  </Link>
                </li>
              )}
              <li>
                <Link to="/faq" className="hover:text-[#123524] transition-colors">
                  FAQ
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-[#123524] transition-colors">
                  Kontakt
                </Link>
              </li>
            </ul>
          </nav>

          {/* Zalogowany lub Gość */}
          {isLoggedIn ? (
            <UserNavigation user={user} handleLogout={handleLogout} />
          ) : (
            <GuestNavigation openLoginModal={openLoginModal} />
          )}
        </div>
      </header>

      {/* Login Modal */}
      {isLoginModalOpen && (
        <LoginModal setIsLoggedIn={setIsLoggedIn} onClose={closeLoginModal} />
      )}
    </>
  );
}

export default Navigation;
