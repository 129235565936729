import React, { useState } from 'react';

const CardLayout = () => {
  const [favorites, setFavorites] = useState([]); // Pusta tablica oznacza brak ulubionych

  const listings = [
    {
      id: 1,
      title: 'VW Golf 7 1.4 TSI',
      subtitle: '(dane z tabelek), (+ krótki opis sprzedającego + nagłówka, max 60 znaków)',
      price: '50 000 zł',
      fuel: 'Benzyna',
      engineCapacity: '1398 cm³',
      power: '122 KM',
      mileage: '150 000 km',
      year: '2015',
      location: 'Mazowieckie',
      city: 'Warszawa',
      transmission: 'Manual',
      drive: 'Napęd przedni',
      image: '/images/automobile-1834278_640.jpg',
    },
    {
      id: 2,
      title: 'BMW 3 E36 1.8 is',
      subtitle: '(dane z tabelek), (+ krótki opis sprzedającego + nagłówka, max 60 znaków)',
      price: '30 000 zł',
      fuel: 'Benzyna',
      engineCapacity: '1802 cm³',
      power: '140 KM',
      mileage: '223 565 km',
      year: '1993',
      location: 'Mazowieckie',
      city: 'Warszawa',
      transmission: 'Manual',
      drive: 'Napęd tylny',
      image: '/images/car-1880381_640.jpg',
    },
  ];

  const toggleFavorite = (id) => {
    setFavorites((prev) =>
      prev.includes(id) ? prev.filter((favId) => favId !== id) : [...prev, id]
    );
  };

  const navigateToListing = (id) => {
    // Symulacja nawigacji — zmień to na użycie routera (np. react-router-dom)
    console.log(`Przejdź do szczegółów ogłoszenia o ID: ${id}`);
    window.location.href = `/listing/${id}`; // Zmień to, jeśli korzystasz z routera
  };

  return (
    <div className="max-w-7xl mx-auto px-4">
      <div className="space-y-4">
        {listings.map((listing) => (
          <div
            key={listing.id}
            className="bg-white shadow-md rounded-lg flex overflow-hidden hover:bg-gray-50 cursor-pointer"
            onClick={() => navigateToListing(listing.id)} // Dodaj kliknięcie na całe ogłoszenie
          >
            {/* Obrazek */}
            <div className="w-48 h-32 flex-shrink-0">
              <img
                src={listing.image}
                alt={listing.title}
                className="w-full h-full object-cover"
              />
            </div>

            {/* Szczegóły */}
            <div className="flex-grow px-4 py-4">
              <div className="font-medium text-xl">{listing.title}</div>
              <div className="text-base text-gray-600 mt-2">{listing.subtitle}</div>
              <div className="flex items-center space-x-2 mt-4 text-gray-800 text-sm">
                <span>{listing.fuel}</span>
                <span className="border-l border-gray-300 px-2">{listing.engineCapacity}</span>
                <span className="border-l border-gray-300 px-2">{listing.power}</span>
                <span className="border-l border-gray-300 px-2">{listing.mileage}</span>
                <span className="border-l border-gray-300 px-2">{listing.year}</span>
                <span className="border-l border-gray-300 px-2">{listing.drive}</span>
              </div>
            </div>

            {/* Lokalizacja i Cena */}
            <div className="flex flex-col items-center justify-center px-6 border-l">
              <div className="text-lg text-gray-700 flex items-center mb-4">
                <span role="img" aria-label="location" className="mr-2 text-pink-500">📍</span>
                {listing.city}, {listing.location}
              </div>
              <div className="flex items-center justify-center border-t pt-2">
                <span className="text-2xl text-gray-900 font-semibold flex items-center">
                  <span role="img" aria-label="price" className="mr-2">💰</span>
                  {listing.price}
                </span>
              </div>
            </div>

            {/* Ikonka Ulubionych */}
            <div
              className="flex items-center justify-center px-6 border-l cursor-pointer"
              onClick={(e) => {
                e.stopPropagation(); // Zatrzymaj propagację, aby kliknięcie nie przechodziło na ogłoszenie
                toggleFavorite(listing.id);
              }}
              title={favorites.includes(listing.id) ? "Usuń z ulubionych" : "Dodaj do ulubionych"}
            >
              <span
                role="img"
                aria-label="car"
                className={`text-3xl ${
                  favorites.includes(listing.id) ? 'text-red-500' : 'text-gray-300'
                }`}
              >
                🚗
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardLayout;
