// ListingsPage.js
import React, { useState } from 'react';
import ListingListItem from './ListingListItem';
import ListingCard from './ListingCard'; // Upewnij się, że importujesz ListingCard
import ViewToggle from './ViewToggle';
import CompactSearch from './CompactSearch';
import { Search } from 'lucide-react';

const generateSampleListings = () => {
  const listings = [];
  for (let i = 1; i <= 350; i++) {
    listings.push({
      id: i,
      title: `Samochód ${i} - Marka Model`,
      price: Math.floor(Math.random() * 100000) + 20000,
      year: Math.floor(Math.random() * 22) + 2000,
      condition: i % 2 === 0 ? 'Nowy' : 'Używany',
      mileage: Math.floor(Math.random() * 200000),
      fuel: ['Benzyna', 'Diesel', 'Elektryczny', 'Hybrydowy'][i % 4],
      location: ['Warszawa', 'Kraków', 'Poznań', 'Gdańsk', 'Wrocław'][i % 5],
      imgSrc: [
        `/images/cars/car${(i % 10) + 1}_1.jpg`,
        `/images/cars/car${(i % 10) + 1}_2.jpg`,
        `/images/cars/car${(i % 10) + 1}_3.jpg`,
      ],
      engineCapacity: Math.floor(Math.random() * 2000) + 1000,
      power: Math.floor(Math.random() * 200) + 50,
      gearbox: ['Manualna', 'Automatyczna'][i % 2],
      driveType: ['Napęd przedni', 'Napęd tylny', '4x4'][i % 3],
    });
  }
  return listings;
};

const sampleListings = generateSampleListings();

const ListingsPage = () => {
  const [view, setView] = useState('list'); // Widok listy
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({ sortBy: '', condition: '' });
  const [showSearch, setShowSearch] = useState(false);
  const listingsPerPage = 50; // 50 ogłoszeń na stronę

  // Filtrowanie i sortowanie ogłoszeń
  const filteredListings = sampleListings
    .filter((listing) => {
      if (filter.condition === 'Nowe') return listing.condition === 'Nowy';
      if (filter.condition === 'Używane') return listing.condition === 'Używany';
      return true;
    })
    .sort((a, b) => {
      if (filter.sortBy === 'price-high') return b.price - a.price;
      if (filter.sortBy === 'price-low') return a.price - b.price;
      if (filter.sortBy === 'year-new') return b.year - a.year;
      if (filter.sortBy === 'year-old') return a.year - b.year;
      return 0;
    });

  const totalPages = Math.ceil(filteredListings.length / listingsPerPage);

  const currentListings = filteredListings.slice(
    (currentPage - 1) * listingsPerPage,
    currentPage * listingsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0); // Przewiń do góry po zmianie strony
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
    setCurrentPage(1); // Reset paginacji po zmianie filtra
  };

  return (
    <div className="relative w-full min-h-screen bg-gray-100">
      <div className="relative container mx-auto max-w-screen-xl px-4 py-8">
        {/* Nagłówek z wyszukiwarką, filtrami i przełącznikiem widoku */}
        <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-6">
          {/* Wyszukiwarka */}
          <div className="mb-4 md:mb-0">
            <button
              onClick={() => setShowSearch(!showSearch)}
              className="flex items-center gap-2 bg-white px-4 py-2 rounded-lg hover:bg-gray-100 transition-colors duration-200 shadow-md text-green-700 font-medium"
            >
              <Search size={20} />
              {showSearch ? 'Ukryj wyszukiwarkę' : 'Pokaż wyszukiwarkę'}
            </button>
          </div>

          {/* Filtry (wyśrodkowane) */}
          <div className="flex gap-4 mb-4 md:mb-0">
            <select
              name="condition"
              value={filter.condition}
              onChange={handleFilterChange}
              className="bg-white border border-gray-300 px-4 py-2 rounded-lg focus:ring-green-600 text-sm"
            >
              <option value="">Wszystkie</option>
              <option value="Nowe">Nowe</option>
              <option value="Używane">Używane</option>
            </select>

            <select
              name="sortBy"
              value={filter.sortBy}
              onChange={handleFilterChange}
              className="bg-white border border-gray-300 px-4 py-2 rounded-lg focus:ring-green-600 text-sm"
            >
              <option value="">Sortuj według</option>
              <option value="price-high">Cena: najwyższa</option>
              <option value="price-low">Cena: najniższa</option>
              <option value="year-new">Rok: najnowszy</option>
              <option value="year-old">Rok: najstarszy</option>
            </select>
          </div>

          {/* Przełącznik widoku */}
          <div>
            <ViewToggle view={view} onToggleView={setView} />
          </div>
        </div>

        {/* Wyszukiwarka */}
        {showSearch && (
          <div className="mb-6">
            <CompactSearch />
          </div>
        )}

        {/* Lista ogłoszeń */}
        {view === 'list' ? (
          <div className="space-y-4">
            {currentListings.map((listing) => (
              <ListingListItem key={listing.id} listing={listing} />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {currentListings.map((listing) => (
              <ListingCard key={listing.id} listing={listing} />
            ))}
          </div>
        )}

        {/* Paginacja */}
        {totalPages > 1 && (
          <div className="flex justify-center gap-2 mt-6">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`px-3 py-1.5 rounded-md ${
                  currentPage === index + 1
                    ? 'bg-green-600 text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-50'
                } transition-colors duration-200`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListingsPage;
