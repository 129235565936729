import React, { useState } from 'react';

const carData = {
  Audi: ['A1', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'Q3', 'Q5', 'Q7', 'Q8', 'TT', 'R8'],
  BMW: ['Seria 1', 'Seria 2', 'Seria 3', 'Seria 4', 'Seria 5', 'Seria 6', 'Seria 7', 'Seria 8', 'X1', 'X2', 'X3', 'X4', 'X5', 'X6', 'X7'],
  Mercedes: ['Klasa A', 'Klasa B', 'Klasa C', 'Klasa E', 'Klasa S', 'CLA', 'CLS', 'GLA', 'GLB', 'GLC', 'GLE', 'GLS'],
  Volkswagen: ['Golf', 'Polo', 'Passat', 'Arteon', 'T-Roc', 'T-Cross', 'Tiguan', 'Touareg', 'ID.3', 'ID.4'],
  Toyota: ['Yaris', 'Corolla', 'Camry', 'RAV4', 'C-HR', 'Highlander', 'Land Cruiser', 'Supra', 'Prius'],
  Ford: ['Fiesta', 'Focus', 'Mondeo', 'Kuga', 'Puma', 'Edge', 'Mustang', 'Explorer'],
  Opel: ['Corsa', 'Astra', 'Insignia', 'Crossland', 'Grandland', 'Mokka'],
  Hyundai: ['i20', 'i30', 'i40', 'Kona', 'Tucson', 'Santa Fe', 'IONIQ', 'NEXO'],
  Kia: ['Picanto', 'Rio', 'Ceed', 'Proceed', 'Stinger', 'XCeed', 'Sportage', 'Sorento'],
  Škoda: ['Fabia', 'Scala', 'Octavia', 'Superb', 'Kamiq', 'Karoq', 'Kodiaq', 'Enyaq'],
};

const bodyTypes = ['Sedan', 'Hatchback', 'SUV', 'Kombi', 'Coupé', 'Cabrio', 'VAN', 'Pickup'];

const advancedOptions = {
  damageStatus: ['Brak uszkodzeń', 'Lekko uszkodzony', 'Poważnie uszkodzony'],
  country: ['Polska', 'Niemcy', 'Francja', 'Włochy'],
  fuelType: ['Benzyna', 'Diesel', 'Elektryczny', 'Hybrydowy'],
  driveType: ['Przedni', 'Tylny', '4x4'],
};

const SearchForm = () => {
  const [formData, setFormData] = useState({
    make: '',
    model: '',
    priceFrom: '',
    priceTo: '',
    yearFrom: '',
    yearTo: '',
    bodyType: '',
    damageStatus: '',
    country: '',
    fuelType: '',
    driveType: '',
    mileageFrom: '',
    mileageTo: '',
    location: '',
  });

  const [availableModels, setAvailableModels] = useState([]);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === 'make') {
      setAvailableModels(carData[value] || []);
      setFormData((prev) => ({ ...prev, model: '' }));
    }
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: currentYear - 1989 }, (_, i) => currentYear - i);
  };

  const handleSearch = () => {
    console.log('Parametry wyszukiwania:', formData);
    // Możesz dodać logikę przesyłania danych do API
  };

  return (
    <div className="flex justify-center items-center py-12 bg-white">
      <div
        className="relative max-w-6xl w-full rounded-lg shadow-lg p-6"
        style={{
          backgroundImage: "url('/images/auto-788747_1280.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg"></div>
        <div className="relative z-10">
          <h2 className="text-4xl text-white font-bold text-center mb-6">Wyszukaj pojazd</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-white">Marka pojazdu</label>
              <select
                name="make"
                value={formData.make}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
              >
                <option value="">Wybierz markę</option>
                {Object.keys(carData).map((make) => (
                  <option key={make} value={make}>
                    {make}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Model pojazdu</label>
              <select
                name="model"
                value={formData.model}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
                disabled={!formData.make}
              >
                <option value="">Wybierz model</option>
                {availableModels.map((model) => (
                  <option key={model} value={model}>
                    {model}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Cena od (PLN)</label>
              <input
                type="text"
                name="priceFrom"
                value={formData.priceFrom}
                onChange={handleInputChange}
                placeholder="Cena od"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Cena do (PLN)</label>
              <input
                type="text"
                name="priceTo"
                value={formData.priceTo}
                onChange={handleInputChange}
                placeholder="Cena do"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Rok produkcji od</label>
              <select
                name="yearFrom"
                value={formData.yearFrom}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
              >
                <option value="">Wybierz rok</option>
                {generateYearOptions().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Rok produkcji do</label>
              <select
                name="yearTo"
                value={formData.yearTo}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
              >
                <option value="">Wybierz rok</option>
                {generateYearOptions().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-white">Typ nadwozia</label>
              <select
                name="bodyType"
                value={formData.bodyType}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
              >
                <option value="">Wybierz typ nadwozia</option>
                {bodyTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Zaawansowane wyszukiwanie */}
          {showAdvanced && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6">
              <div>
                <label className="block text-sm font-medium text-white">Stan uszkodzeń</label>
                <select
                  name="damageStatus"
                  value={formData.damageStatus}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
                >
                  <option value="">Wybierz stan uszkodzeń</option>
                  {advancedOptions.damageStatus.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-white">Kraj pochodzenia</label>
                <select
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
                >
                  <option value="">Wybierz kraj</option>
                  {advancedOptions.country.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-white">Rodzaj paliwa</label>
                <select
                  name="fuelType"
                  value={formData.fuelType}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
                >
                  <option value="">Wybierz rodzaj paliwa</option>
                  {advancedOptions.fuelType.map((fuel) => (
                    <option key={fuel} value={fuel}>
                      {fuel}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-white">Przebieg od (km)</label>
                <input
                  type="text"
                  name="mileageFrom"
                  value={formData.mileageFrom}
                  onChange={handleInputChange}
                  placeholder="Przebieg od"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-white">Przebieg do (km)</label>
                <input
                  type="text"
                  name="mileageTo"
                  value={formData.mileageTo}
                  onChange={handleInputChange}
                  placeholder="Przebieg do"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-white">Lokalizacja</label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  placeholder="Wprowadź lokalizację"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-white">Silnik i napęd</label>
                <select
                  name="driveType"
                  value={formData.driveType}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-green-500 focus:border-green-500"
                >
                  <option value="">Wybierz silnik i napęd</option>
                  {advancedOptions.driveType.map((drive) => (
                    <option key={drive} value={drive}>
                      {drive}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

         <div className="mt-12 relative">
  {/* Przycisk Zaawansowane wyszukiwanie */}
  <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0">
    <button
      onClick={() => setShowAdvanced(!showAdvanced)}
      className="text-green-500 hover:text-green-700 text-lg font-semibold px-4 py-2"
    >
      {showAdvanced ? 'Ukryj zaawansowane wyszukiwanie' : 'Zaawansowane wyszukiwanie'}
    </button>
  </div>

  {/* Przycisk Pokaż ogłoszenia (po prawej stronie) */}
  <div className="absolute right-0 bottom-0">
    <button
      onClick={handleSearch}
      className="bg-green-600 text-white text-lg px-6 py-3 rounded hover:bg-green-700"
    >
      Pokaż ogłoszenia
    </button>
  </div>
</div>

        </div>
      </div>
    </div>
  );
};

export default SearchForm;
