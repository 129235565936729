import React from 'react';

function Banner() {
  return (
    <div className="relative w-full h-[45rem] bg-gray-200 mt-0">
      {/* Tło z przyciemnieniem */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat brightness-[0.3]"
        style={{
          backgroundImage: `url('/images/mercedes-benz-841465_1920.jpg')`,
        }}
      ></div>

      {/* Treść na banerze */}
      <div className="relative z-20 flex flex-col items-center justify-center h-full px-4 text-center">
        <h1 className="font-extrabold text-8xl sm:text-9xl tracking-tighter mb-6 bg-gradient-to-r from-white via-yellow-100 to-white bg-clip-text text-transparent drop-shadow-[0_5px_5px_rgba(0,0,0,0.5)]">
          AutoSell<span className="text-yellow-500">.pl</span>
        </h1>
        <p className="text-white text-3xl sm:text-4xl font-light tracking-wide mt-4">
          Samochód na <span className="text-yellow-500 font-semibold">każdą</span> okazję!
        </p>
      </div>

      {/* Gradient na dole */}
      <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black/50 to-transparent"></div>
    </div>
  );
}

export default Banner;