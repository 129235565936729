// src/components/Footer.js
import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-300 pt-6 pb-2"> {/* Zmniejszone paddingi */}
      <div className="container mx-auto px-4 lg:px-6 space-y-6"> {/* Zmniejszone odstępy i paddingi */}
        
        {/* Górna linia oddzielająca */}
        <hr className="border-t border-gray-700 w-full mx-auto rounded-full" />

        {/* Główna sekcja stopki */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 pb-6"> {/* Zmniejszone gap i padding */}
          
          {/* Sekcja "Znajdź nas" */}
          <div className="text-center lg:text-left">
            <h3 className="text-base font-semibold text-white mb-3">Znajdź nas</h3> {/* Zmniejszony rozmiar czcionki i margines */}
            <div className="flex justify-center lg:justify-start space-x-3">
              <a href="https://facebook.com" aria-label="Facebook" className="text-gray-400 hover:text-white transition-colors"><FaFacebookF size={20} /></a>
              <a href="https://instagram.com" aria-label="Instagram" className="text-gray-400 hover:text-white transition-colors"><FaInstagram size={20} /></a>
              <a href="https://twitter.com" aria-label="Twitter" className="text-gray-400 hover:text-white transition-colors"><FaTwitter size={20} /></a>
              <a href="https://linkedin.com" aria-label="LinkedIn" className="text-gray-400 hover:text-white transition-colors"><FaLinkedinIn size={20} /></a>
            </div>
          </div>

          {/* Sekcja "Obsługa klienta" */}
          <div className="text-center lg:text-left">
            <h3 className="text-base font-semibold text-white mb-3">Obsługa klienta</h3>
            <p className="text-sm">Telefon: <a href="tel:+48123456789" className="hover:text-white">+48 123 456 789</a></p>
            <p className="text-sm">Email: <a href="mailto:pomoc@autosell.pl" className="hover:text-white">pomoc@autosell.pl</a></p>
            <p className="text-sm mt-1">Pon-Pt: 08:00 - 17:00</p>
          </div>

          {/* Sekcja "AutoSell" */}
          <div className="text-center lg:text-left">
            <h3 className="text-base font-semibold text-white mb-3">AutoSell</h3>
            <ul className="space-y-1 text-sm">
              <li><a href="/pomoc" className="hover:text-white">Pomoc</a></li>
              <li><a href="/kontakt" className="hover:text-white">Kontakt</a></li>
              <li><a href="/reklama" className="hover:text-white">Reklama</a></li>
              <li><a href="/polityka-prywatnosci" className="hover:text-white">Polityka prywatności</a></li>
              <li><a href="/regulamin" className="hover:text-white">Regulamin</a></li>
            </ul>
          </div>

          {/* Sekcja "Przydatne informacje" */}
          <div className="text-center lg:text-left">
            <h3 className="text-base font-semibold text-white mb-3">Przydatne informacje</h3>
            <ul className="space-y-1 text-sm">
              <li><a href="/cennik" className="hover:text-white">Cennik</a></li>
              <li><a href="/testy-samochodow" className="hover:text-white">Testy samochodów</a></li>
              <li><a href="/internetowy-samochod-roku" className="hover:text-white">Internetowy Samochód Roku</a></li>
              <li><a href="/mapa-kategorii" className="hover:text-white">Mapa kategorii</a></li>
              <li><a href="/kariera" className="hover:text-white">Kariera</a></li>
            </ul>
          </div>
        </div>

        {/* Dolna linia oddzielająca */}
        <hr className="border-t border-gray-700 w-full mx-auto rounded-full" />

        {/* Linki prawne oraz prawa autorskie */}
        <div className="flex flex-col md:flex-row justify-between items-center text-xs text-gray-500 pt-3">
          <div className="flex space-x-3">
            <a href="/privacy-policy" className="hover:text-white">Polityka Prywatności</a>
            <a href="/terms" className="hover:text-white">Regulamin</a>
            <a href="/cookie-policy" className="hover:text-white">Polityka Cookies</a>
            <a href="/rodo" className="hover:text-white">RODO</a>
          </div>
          <p className="text-center mt-3 md:mt-0">© 2024 AutoSell. Wszelkie prawa zastrzeżone.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
